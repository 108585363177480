.shortAddress {
  display: flex;
  flex-flow: row nowrap;
  gap: 6px;
  align-items: center;
}

.shortAddress > div {
  padding-top: -2px;
}

.shortAddress span {
  letter-spacing: 0.2px;
  font-family: 'PT Root UI';
  font-weight: bold;
}

.shortAddressLargeText {
  display: flex;
  flex-flow: row nowrap;
  gap: 6px;
  align-items: center;
}

.shortAddressLargeText > div {
  padding-top: -2px;
}

.shortAddressLargeText span {
  letter-spacing: 0.2px;
  font-family: 'Londrina Solid';
  font-weight: bolder;
  font-size: xx-large;
}

