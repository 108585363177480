@import './css/colors.css';

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}

.alertButton {
  width: 60%;
  height: 3rem;
  
  /* color: white; */
  border: transparent;
  background-color: var(--brand-black);
  font-weight: bold;
  font-family: 'PT Root UI';
  font-size: 18px;
  border-radius: 10px;
}

.alertButton:hover,
.alertButton:active,
.alertButton:focus,
.alertButton:disabled {
  background-color: gray !important;
  color: rgb(209, 207, 207);
  outline: none !important;
  box-shadow: none;
}

.buttonRow {
  font-family: 'PT Root UI';
  font-weight: 500;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  font-size: 18px;
  display: flex;
}

.buttonRowText {
  padding-left: 4;
  font-family: 'PT Root UI';
  font-family: bold;
}

@media (max-width: 992px) {
  .alertButton {
    width: 70%;
    height: 3rem;
  }
}