.container {
  width: 100%;
  margin-top: 1rem;
  position: relative;
}

.labelContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label {
  opacity: 0.5;
}

.sublabel {
  opacity: 0.5;
  font-size: 0.8em;
}

.entry {
  border-radius: 15px;
  width: 100%;
  height: 3rem;
  font-size: 22px;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: var(--brand-cool-dark-text);
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  outline: none;
  transition: all ease-in-out 125;
}

.invalid {
  border: 2px solid var(--brand-color-red) !important;
}
