.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(75, 75, 75, 0.5);
    backdrop-filter: blur(24px);
    transition: opacity 50ms ease-in-out;
    /* default value set of transitions */
    opacity: 0;
  }
  
  .backdrop-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .backdrop-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .backdrop-exit {
    opacity: 1;
  }
  .backdrop-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
  
  .content {
    max-height: 50vh;
    overflow-y: hidden;
  }
  
  .modal {
    font-family: 'PT Root UI', sans-serif;
    font-weight: bold;
    position: fixed;
    top: 15vh;
    z-index: 100;
    background-color: rgba(244, 244, 248, 1);
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
    padding: 1.5rem;
    border-radius: 24px;
    left: calc(50% - 236px);
    width: 472px;
    transition: all 125ms ease-in-out;
    /* set defaults for transition */
    transform: translateY(-1rem) scale(0.5);
  }
  
  .modal .header {
    display: flex;
  }
  
  .modal .title {
    font-family: 'Londrina Solid';
    display: flex;
    flex-direction: column;
  }
  
  .modal .title h2 {
    font-size: 24px;
    margin-top: 0.5rem;
    color: rgba(140, 141, 146, 1);
  }
  
  .modal .title h1 {
    line-height: 42px;
    height: 2rem;
    font-size: 42px;
  }
  
  .closeBtnWrapper {
    padding: 1rem 2rem;
    display: flex;
    justify-content: flex-end;
  }
  
  @media (min-width: 992px) {
    .closeBtnWrapper {
      position: absolute;
      left: 96.5%;
    }
  }
  
  @media (max-width: 992px) {
    .modal {
      transform: translateY(20rem) scale(1);
    }
  }
  
  .closeBtn {
    z-index: 100;
    position: fixed;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    transition: all 0.125s ease-in-out;
    border: 0;
  }
  
  .closeBtn:hover {
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
  }
  
  .closeBtn .icon {
    height: 24px;
    width: 24px;
  }
  
  @media (max-width: 992px) {
    .backdrop {
      background: rgba(0, 0, 0, 0.74);
    }
  
    .content {
      max-height: 100%;
      height: 100%;
    }
  
    .modal {
      top: auto;
      left: 0;
      bottom: 0;
      width: 100%;
      max-height: 100%;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: none;
    }
  }