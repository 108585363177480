@tailwind base;
@tailwind components;
@tailwind utilities;

.lodrina {
  font-family: 'Londrina Solid';
}

.inter {
  font-family: 'Inter';
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1140px !important;
  }
}

body {
  overflow-x: hidden;
}
