.proposals {
    display: flex;
    flex-direction: column;
    margin: 0%;
}

.proposals>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.subHeaderRow {
    font-family: 'PT Root UI';
    font-weight: 500;
    color: var(--brand-gray-light-text);
    font-size: 16px;
    line-height: 22.4px;
}