.heading {
  display: inline-block;
  font-weight: bold;
  font-size: 3rem;
}

.headerRow span {
  color: #8c8d92;
  font-size: 24px;
  font-family: 'Londrina Solid';
}

.headerRow h1 {
  color: #14161b;
  font-size: 56px;
  font-family: 'Londrina Solid';
}

.subheading {
  color: var(--brand-dark-green);
  font-family: 'PT Root UI';
  font-weight: 500;
  font-size: 1.2rem;
}

.boldText {
  font-family: 'PT Root UI';
  font-weight: bold;
}

p {
  padding-top: 1rem;
}

@media (max-width: 992px) {
  .section {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .headerRow h1 {
    font-size: 46px;
  }
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
}
