.wrapper h1 {
  font-family: 'Londrina Solid';
  font-size: 5rem;
}

@media (max-width: 992px) {
  .wrapper {
    padding: 2rem;
  }

  .wrapper h1 {
    font-size: 3.75rem;
  }
}

@media (min-width: 992px) {
  .wrapper h1 {
    font-size: 6rem;
    margin-left: 2rem;
  }
}

.videoContainer {
  position: relative;
  padding-bottom: 56.25%; /* Aspect ratio for 16:9 */
  height: 0;
  overflow: hidden;
}

.videoContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}