.bidRow {
  border-radius: 14px;
  width: 100%;
  padding: 1rem;
  margin-top: 0.75rem;
  border-bottom: 0px;
  background-color: white;
}

.bidRow:hover {
  background: rgb(234, 234, 239);
  color: inherit !important;
  cursor: pointer;
}

.expandedBidRow {
  min-height: 72px;
  max-height: none;
}

.bidderInfoWrapper {
  display: flex;
}

.bidderInfoText {
  margin-left: 0.5rem;
  display: inline-block;
  padding: 0;
  line-height: 23px;
}

.bidderCommentText {
  margin-right: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0;
  line-height: 21px;
  text-align: right;
}

.expandedComment {
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: 500;
  font-family: 'PT Root UI';
  font-size: 15px;
}

.expandedContent {
  display: none;
  flex-direction: column;
  width: 100%;
}

.bidDate {
  color: rgba(140, 141, 146, 1);
  font-weight: 500;
  font-family: 'PT Root UI';
  font-size: 13px;
}

.trophy {
  margin-left: 0.5rem;
  margin-bottom: 0.1rem;
}

.linkIcon {
  color: var(--brand-gray-light-text);
  transition: all 0.125s ease-in-out;
  margin-bottom: 0.1rem;
}

.linkIcon:hover {
  color: black;
  cursor: pointer;
}

.bidAmount {
  white-space: nowrap;
}

.bidComment {
  color: rgba(140, 141, 146, 1);
  font-weight: 500;
  font-family: 'PT Root UI';
  font-size: 13px;
  max-width: 100%;
}

.flexContainer {
  display: flex;
  align-items: center;
  gap: 0.1rem;
}
