.columns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  overflow: visible;
  position: relative;
  align-content: flex-start;
  flex-wrap: nowrap;
  gap: 0;
  border-radius: 16px;
  border: 1px solid #DEDEDE;
  max-width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 5px;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}


.full {
  width: 100%;
  margin: 0;
}


.voterDetails {
  padding-left: 8px;
  padding-right: 8px;
  word-break: break-all;
  max-width: 200px;
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 16;
}

.heading {
  font-family: 'PT Root UI';
  font-weight: bold;
  color: #D63C5E;
  font-size: 16;
}

.subheading {
  font-family: 'PT Root UI';
  font-weight: bold;
  color: black;
  font-size: 16;
}




.ensCol {
  padding: 0;
  margin: 0;
}

.nounsCol {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}

.nounsCount {
  margin-left: 0.5rem;
}

.voteSupport {
  margin-top: 0 !important;
}

.reasonRow {
  margin-top: 1rem;
  transition: height 0.3s ease-in-out;
  /* background-color: red; */
}

.reasonText {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  cursor: pointer;
  transition: height 0.3s ease-in-out;
  max-height: 5rem;
  word-wrap: break-word;
  word-break: break-all;
  font-family: 'PT Root UI';
  font-weight: medium;
  font-size: 14;
  color: #8C8D92;
}

.reasonText.expanded {
  max-height: none !important;
}

.expandText {
  height: auto !important;
  transition: height 0.3s ease-in-out;
}

.truncateText {
  height: 4rem !important;
}