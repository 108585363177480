.voteSupport {
  font-family: 'PT Root UI';
  font-weight: bold;
  color: white;
  border-radius: 8px;
  font-size: 14px;
  border: 0rem;
  padding-left: 0.65rem;
  padding-right: 0.65rem;
  padding-top: 0.36rem;
  padding-bottom: 0.36rem;
}

.for {
  background-color: var(--brand-color-green);
}

.against {
  background-color: var(--brand-color-red);
}

.abstain {
  background-color: var(--brand-gray-light-text);
}

.votePageProposalSupport {
  margin-top: 0.8rem;
}
