.popover {
  max-width: 600px;
}

.transactionDetails {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border: 1px solid #aaa !important;
  border-radius: 0.25rem !important;
}

.removeTransactionButton {
  border: none;
  background: none;
  outline: none !important;
  box-shadow: none !important;
}

.removeTransactionButton img {
  width: 1rem;
}
.simulateTransactionButton {
  height: 40px;
  border-radius: 8px;
  font-family: 'PT Root UI';
  font-weight: bold;
  transition: all 0.125s ease-in-out;
  margin-top: 10px;
  margin-bottom: 20px;
}

.simulateTransactionButton:hover {
  opacity: 0.5;
  cursor: pointer;
}

.simulateTransactionButtonSection {
  border-top: 0px;
}
