.voteTable {
  width: 926px;
  height: min-content;
  /* 1162px */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: visible;
  padding: 0px 0px 0px 0px;
  align-content: flex-start;
  flex-wrap: nowrap;
  gap: 16;
  position: absolute;
  border-radius: 0px 0px 0px 0px;
  /* background-color: red; */
}

.section {
  word-wrap: break-word;
  padding-top: 2rem;
  margin-top: 2rem;
}

.section h5 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-family: 'Londrina Solid';
}

.markdown {
  font-family: 'PT Root UI';
  font-size: 1.1rem;
}

.markdown h1 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-weight: bold;
}

.markdown h2 {
  font-size: 1.5rem;
  margin-top: 1rem;
  font-weight: bold;
}

.markdown h3 {
  font-weight: bold;
  font-size: 1.3rem;
}

.markdown img {
  max-width: 100%;
  height: auto;
}

.governanceSwitchBtn {
  max-width: 10rem;
  font-size: 16px;
  font-weight: bold;
  color: #8C8D92;
  margin-bottom: 0.5rem;
  margin-right: 8px;

  border-radius: 32.5px;
  background-color: #FFFFFF;
  border: 2px solid #E2E3E8;
  height: 43px;
  width: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 16px;
}

.governanceSwitchBtnActive {
  max-width: 10rem;
  font-size: 16px;
  font-weight: bold;
  color: #8C8D92;
  margin-bottom: 0.5rem;
  margin-right: 8px;

  border-radius: 32.5px;
  background-color: #FFFFFF;
  height: 43px;
  width: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 16px;

  color: #14161b;
  border: 2px solid var(--brand-color-blue);
}

.governanceSwitchBtn2 {
  max-width: 10rem;
  font-size: 16px;
  font-weight: bold;
  color: #8C8D92;
  border: 2px solid var(--brand-color-blue);
  margin-bottom: 0.5rem;

  border-radius: 32.5px;
  background-color: #FFFFFF;
  border: 2px solid #E2E3E8;
  height: 43px;
  width: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 16px;
}

.governanceSwitchBtn2Active {
  max-width: 10rem;
  font-size: 16px;
  font-weight: bold;
  color: #8C8D92;
  border: 2px solid var(--brand-color-blue);
  margin-bottom: 0.5rem;

  border-radius: 32.5px;
  background-color: #FFFFFF;
  border: 2px solid #E2E3E8;
  height: 43px;
  width: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 16px;

  color: #14161b;
  border: 2px solid var(--brand-color-blue);
}


.container {
  height: auto;
  padding-bottom: 30rem;
  /* background-color: red; */
}

@media (min-width: 768px) {
  .equal>[class^="col"] {
    flex-basis: 100%;
  }

  .equal>[class^="col"]:not(:last-child) {
    margin-right: 0;
  }

  .equal>[class^="col"]:not(:first-child) {
    margin-left: 0;
  }

  .equal>.col-4 {
    flex-basis: calc(33.33% - 1rem);
  }

  .equal>.col-6 {
    flex-basis: calc(50% - 1rem);
  }

  .equal>.col {
    flex-grow: 1;
    max-width: 298px;
  }
}

.cell {
  margin-bottom: 16px;
  flex-grow: 1;
}

.emptyState {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'PT Root UI';
  font-weight: medium;
  font-size: 14;
  color: #8C8D92;
}